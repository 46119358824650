import { Description } from "@mui/icons-material";

export const loginInitialValues = {
  email: "",
  password: "",
  rememberMe: false,
};

export const addAdminInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  mobile: '',
  image: '',
  profile: '',
  companyId: ""
};
// helper/initialValues.js
export const addCompanyInitialValues = {
  companyName: "",
  email: "",
  contactNumber: "",
  probationPeriod: "",
  website: "",
  address: "",
  zipcode: "",
  state: "",
  city: "",
};

export const filterAdminInitialValues = {
  status: "",
  role: "",
};

export const filterCompanyInitialValues = {
  status: "",
};
export const filterHolidayInitialValues = {
  status: "",
  employee: "",
  leaveType: "",
  startDate: "",
  endDate: "",
};
export const filterHoliday = {
  status: "",
  type: "",
  company: "",
};

export const filterEmployeeInitialValues = {
  status: "",
  company: "",
  employee: "",
  fromDate: "",
  toDate: "",
}

export const roleInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
};

export const filterRoleInitialValues = {
  status: "",
};

export const roleAddInitialValues = {
  roleName: "",
};
export const holidayAddInitialValues = {
  title: "",
  companyId: "",
  day: "",
  date: "",
  description: "",
  type: "",
};

export const leaveAddInitialValues = {
  employeeId: "",
  startDate: "",
  endDate: "",
  reason: "",
  type: "",
};

export const salaryDetailAddInitialValues = {
  employeeId: "",
  basicSalary: "",
  sequrityDeposit: "",
};

export const LeaveSettlementInitialVaue = {
  employeeId: "",
  newLeaves: "",
};

export const addEmployeeInitialValues = {
  companyId: "",
  salutation: "",
  firstName: "",
  middleName: "",
  lastName: "",
  DOB: "",
  gender: "",
  contact: "",
  email: "",
  designation: "",
  fathersName: "",
  fathersContact: "",
  maritalStatus: "",
  panNumber: "",
  adharNumber: "",
  passportNumber: "",
  passportValidupto: "",
  departments: "",
  branch: "",
  joining_date: "",
  nameAsPerBank: "",
  bankName: "",
  bankBranch: "",
  accountNumber: "",
  IFSCNumber: "",
  upiId: "",
  employeementType: "",
  employeementStatus: "",
  educationLevel: "",
  course: "",
  specification: "",
  startYear: "",
  passoutYear: "",
  CGPA: "",
  basicSalary: "",
  deduction: "",
  empCode: "",
  altContact: "",
  bloodGroup: "",
  perosnalEmail: "",
  spouseName: "",
  contactOffice: "",
  motherName: "",
  motherContact: "",
  spouseContact: "",
  emergencyContact: "",
  id: "",
  pLine1: "",
  pLine2: "",
  pState: "",
  pCity: "",
  pZipcode: "",
  cLine1: "",
  cLine2: "",
  cState: "",
  cCity: "",
  cZipcode: "",
};

export const addFormInitialValues = {
  title: "",
  location: "",
  notes: "",
  questions: [],
  expireDate: null,

};

export const filterAuditorInitialValues = {
  status: "",
  email: "",
  mobile: ""
};

export const groupInitialValues = {
  groupName: "",
  auditors: "",
};

export const formFilterInitialValues = {
  fromExpireDate: "",
  toExpireDate: "",
  fromPublishedDate: "",
  toPublishedDate: "",
  status: "",
};

export const filterGroupInitialValues = {
  status: "",
};

export const assignFormInitialValues = {
  groups: [],
};

export const extendFormInitialValues = {
  extendDate: null,
};

export const addDocumentInitialValues = {
  employeeId: '',
  passport: '',
  educationsDoc: '',
  preEmpDoc: '',
  joiningDoc: '',
  adharCardFront: '',
  adharCardBack: '',
  panCardFront: '',
  panCardBack: '',
  panCardFrontlink: '',
  panCardBacklink: '',
  adharCardFrontlink: '',
  adharCardBacklink: '',
  passportlink: '',
  educationsDoclink: '',
  preEmpDoclink: '',
  joiningDoclink: '',
  preEmpDocName: '',
  joiningDocName: '',
  educationsDocName: '',
  passportName: '',
}

export const adminDetailInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  address1: "",
  address2: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  image: "",
};


export const changePasswordInitialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};


export const salaryInitialValues = {
  companyId: "",
  employee: "",
  date: "",
  status: ""
}
export const payslipInitialValues = {
  companyId: "",
  employee: "",
  date: "",
  status: ""
}

export const salarycalculateInitialValues = {
  companyId: "",
}

export const assignemployeeInitialValues = {
  userId: "",
}

export const incrementInitialValues = {
  designation: "",
  employeeId: "",
  amount: 0,
  totalSalary: "",
  type: 2,
  description: "",
  applicableFrom: "",
  basicSalary: 0,
}

export const uploadEmployeeInitialValues = {
  companyId: "",
  files: []
}