import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { ChangePassword, Delete, Logout } from './modals';
import AddAdmin from '../../pages/UserManagement/Model/Addadmin';
import Addpayslip from './modals/Addpayslip';
import AddHolidayModal from '../../pages/HolidayManagement/AddHolidaymodel';
import AddLeaveModal from '../../pages/Leave and Holiday Management/AddLeaveModal';
import AddLeaveManage from '../../pages/Leave and Holiday Management/AddLeaveManage';
import AddSalaryModal from '../../pages/Salary and Tax Management/EmployeeSalary/AddSalaryModal';
import AddIncrement from './modals/AddIncrement';
import SalaryCalculate from './modals/SalaryCalculate';
import ViewHoliday_model from '../../pages/HolidayManagement/ViewHoliday_model';
import ViewUserModel from '../../pages/UserManagement/ViewUserModel';
import UploadProduct from '../../pages/Employee Data/modal/UploadProduct';
import AddSalaryDetailsModal from '../../pages/Salary and Tax Management/AddSalaryDetailsModal';
import LeavebalanceModal from '../../pages/Leave and Holiday Management/LeavebalanceModal';
import Assign_Employee_Modal from '../../pages/Employee Data/modal/Assign_Employee_Modal';

export default function CommonModal({ handleClose, modalOpen, handelClick, files, companyData, setFiles, refresh, setRefresh, formik }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
      className={modalOpen.class}
    >
      {modalOpen.currentComponent === 'changePassword' && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} formik={formik} />
      )}
      {['delete', 'status', 'changepassword', 'approved', 'reject'].includes(modalOpen.currentComponent) && (
        <Delete handleClose={handleClose} modalOpen={modalOpen} handelClick={handelClick} />
      )}
      {modalOpen.currentComponent === 'logout' && (
        <Logout handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addAdmin' && (
        <AddAdmin handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} />
      )}
      {modalOpen.currentComponent === 'addpayslip' && (
        <Addpayslip handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} />
      )}
      {modalOpen.currentComponent === 'addholiday' && (
        <AddHolidayModal handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} />
      )}
      {modalOpen.currentComponent === 'addleave' && (
        <AddLeaveModal handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} refresh={refresh} setRefresh={setRefresh} />
      )}
      {modalOpen.currentComponent === 'addleaveManage' && (
        <AddLeaveManage handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} refresh={refresh} setRefresh={setRefresh} />
      )}
      {modalOpen.currentComponent === 'addsalary' && (
        <AddSalaryModal handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} />
      )}
      {modalOpen.currentComponent === 'addincrement' && (
        <AddIncrement handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} formik={formik} />
      )}
      {modalOpen.currentComponent === 'salarycalculate' && (
        <SalaryCalculate handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} formik={formik} />
      )}
      {modalOpen.currentComponent === 'viewholiday' && (
        <ViewHoliday_model handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} formik={formik} />
      )}
      {modalOpen.currentComponent === 'viewuser' && (
        <ViewUserModel handleClose={handleClose} modalOpen={modalOpen} files={files} setFiles={setFiles} formik={formik} />
      )}
      {modalOpen.currentComponent === "uploadProduct" && (
        <UploadProduct
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
          files={files}
          setFiles={setFiles}
          handelClick={handelClick}
          companyData={companyData}
        />
      )}
      {modalOpen.currentComponent === 'Salary' && (
        <AddSalaryDetailsModal handleClose={handleClose} modalOpen={modalOpen} formik={formik} refresh={refresh} setRefresh={setRefresh} />
      )}
      {modalOpen.currentComponent === 'addleavebalance' && (
        <LeavebalanceModal handleClose={handleClose} modalOpen={modalOpen} formik={formik} refresh={refresh} setRefresh={setRefresh} />
      )}
        {modalOpen.currentComponent === 'assignemployee' && (
        <Assign_Employee_Modal handleClose={handleClose} modalOpen={modalOpen} formik={formik} refresh={refresh} setRefresh={setRefresh} />
      )}

    </Dialog>
  );
}