import React, { useEffect, useState } from "react";
import { Box, Fade, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { BackButton, Modal } from "../../../components/Common";
import EmployeeInfo from "./EmployeeInfo";
import EmployeeDocument from "./EmployeeDocument";
import EmployeeHistory from "./EmployeeHistory";
import PropTypes from "prop-types";
import SalaryandTaxMang from "../../Salary and Tax Management/SalaryandTaxMang";
import { EmployeeDetail } from "../../../Service/Employee.service";
import { useLocation } from "react-router-dom";
import Offerletter from "../offerletter/Offerletter";
import Leaves_Details from "./Leaves_Details";
import { MdEdit } from "react-icons/md";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EmployeeDetails = () => {
  const [value, setValue] = React.useState(0);
  const [employeeData, setEmployeeData] = React.useState({});
  const { state } = useLocation();

  console.log("state ==================", state);


  const getEmployeeDetails = async (id) => {
    let res = await EmployeeDetail(id);
    if (res?.status) {
      setEmployeeData(res?.data);
    } else {
      setEmployeeData({});
    }
  };

  useEffect(() => {
    if (state?.id) {
      getEmployeeDetails(state.id);
    }
  }, [state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    id: "",
    adminId: ""
  });
  return (
    <>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        gap={1}
        alignItems={"center"}
        mb={2}
        justifyContent={"space-between"}
      >

        <Box sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "5px",
        }}>
          <BackButton />

          <Typography fontWeight={600}>Employee Name : </Typography>
          <Typography className="mainH2">
            {employeeData?.salutation}. {employeeData?.firstName}{" "}
            {employeeData?.lastName}
          </Typography>

        </Box>
        {state?.role && state?.role === 1 && (
          <Box sx={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
            <Typography fontWeight={600}>Assigned to : {employeeData?.assignedTo || "-"}</Typography>
            <Tooltip title="Edit">

              <IconButton sx={{ backgroundColor: 'primary.main', color: 'white', }}>
                <MdEdit
                  fontSize={"12px"}
                  onClick={() =>
                    setIsModalOpen({
                      open: true,
                      currentComponent: "assignemployee",
                      id: employeeData?.id,
                      adminId: employeeData?.assignedBy
                    })
                  } />

              </IconButton>
            </Tooltip>
          </Box>
        )}

      </Stack>

      <Stack gap={2} display={"flex"} flexDirection={"row"} mt={2}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              zIndex: 1,
              borderRadius: "10px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              padding: "10px",
              gap: "10px",
              height: "fit-content !important",
              overflowX: "auto",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                minHeight: "auto", // or '0'
                "& .MuiTabs-root": {
                  minHeight: "auto", // or '0'
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab
                label="Employee Info"
                {...a11yProps(0)}
                sx={{
                  backgroundColor:
                    value === 0 ? "rgba(0, 0, 0, 0.1)" : "transparent",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                  transition: "background-color 0.3s ease-in-out",
                  minHeight: "20px !important", // Adjust the minHeight as needed
                }}
              />
              <Tab
                label="Employee Document"
                {...a11yProps(1)}
                sx={{
                  backgroundColor:
                    value === 1 ? "rgba(0, 0, 0, 0.1)" : "transparent",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                  transition: "background-color 0.3s ease-in-out",
                }}
              />
              <Tab
                label="Increment History"
                {...a11yProps(2)}
                sx={{
                  backgroundColor:
                    value === 2 ? "rgba(0, 0, 0, 0.1)" : "transparent",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                  transition: "background-color 0.3s ease-in-out",
                }}
              />
              <Tab
                label="Salary Structure"
                {...a11yProps(3)}
                sx={{
                  backgroundColor:
                    value === 3 ? "rgba(0, 0, 0, 0.1)" : "transparent",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                  transition: "background-color 0.3s ease-in-out",
                }}
              />
              <Tab
                label="Leave Details"
                {...a11yProps(4)}
                sx={{
                  backgroundColor:
                    value === 4 ? "rgba(0, 0, 0, 0.1)" : "transparent",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                  transition: "background-color 0.3s ease-in-out",
                }}
              />
              <Tab
                label="Offer Letter"
                {...a11yProps(5)}
                sx={{
                  backgroundColor:
                    value === 5 ? "rgba(0, 0, 0, 0.1)" : "transparent",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                  transition: "background-color 0.3s ease-in-out",
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Fade in={value === 0} timeout={500}>
              <div>
                <EmployeeInfo employeeData={employeeData} />
              </div>
            </Fade>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Fade in={value === 1} timeout={500}>
              <div>
                <EmployeeDocument employeeId={employeeData.id} />
              </div>
            </Fade>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Fade in={value === 2} timeout={500}>
              <div>
                <EmployeeHistory employeeId={employeeData.id} />
              </div>
            </Fade>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Fade in={value === 3} timeout={500}>
              <div>
                <SalaryandTaxMang
                  employeeData={employeeData}
                  getEmployeeDetails={getEmployeeDetails}
                />
              </div>
            </Fade>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Fade in={value === 4} timeout={500}>
              <div>
                <Leaves_Details employeeId={employeeData.id} />
              </div>
            </Fade>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <Fade in={value === 5} timeout={500}>
              <div>
                <Offerletter />
              </div>
            </Fade>
          </CustomTabPanel>
        </Box>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", id: "", adminId: "" })
        }
        refresh={getEmployeeDetails}
      />
    </>
  );
};

export default EmployeeDetails;
