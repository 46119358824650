import {
  Autocomplete,
  Box,
  Divider,
  MenuItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Heading, Input, Modal } from "../../../src/components/Common";
import { AddHomeWorkOutlined, AddIcCallOutlined } from "@mui/icons-material";
import { MdCurrencyRupee } from "react-icons/md";
import { EmpSalaryDetails } from "../../Service/Salary.service";

const salarycomponents = [
  {
    salarycompo: "Basic Pay",
    monthly: "19,500",
    Annual: "2,24,000",
    paytype: "Fixed Pay",
  },
  {
    salarycompo: "HRA",
    monthly: "10,000",
    Annual: "1,20,000",
    paytype: "Fixed Pay",
  },
  {
    salarycompo: "Transport Allowance",
    monthly: "2000",
    Annual: "24,000",
    paytype: "Fixed Pay",
  },
  {
    salarycompo: "Medical Allowance",
    monthly: "1250",
    Annual: "15,000",
    paytype: "Fixed Pay",
  },
  {
    salarycompo: "Other Allowance",
    monthly: "500",
    Annual: "6000",
    paytype: "Fixed Pay",
  },
];

const companycontribution = [
  {
    companycontribution: "PF",
    monthly: "1800",
    Annual: "21,600",
    paytype: "-",
  },
  {
    companycontribution: "ESI",
    monthly: "600",
    Annual: "7,200",
    paytype: "-",
  },
  // {
  //   companycontribution: "TDS",
  //   monthly: "-",
  //   Annual: "-",
  //   paytype: "-",
  // },
  // {
  //   companycontribution: "LWF",
  //   monthly: "-",
  //   Annual: "-",
  //   paytype: "-",
  // },
];

const deduction = [
  {
    deduction: "Professional Tax",
    monthly: "200",
    Annual: "2,400",
    paytype: "-",
  },
  {
    deduction: "Income Tax",
    monthly: "300",
    Annual: "3,600",
    paytype: "-",
  },
  {
    deduction: "LOP/LWP",
    monthly: "500",
    Annual: "6,000",
    paytype: "-",
  },
];

const SalaryandTaxMang = ({ employeeData, getEmployeeDetails }) => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [salaryData, setSalaryData] = useState([]);
  const [salaryDataAll, setSalaryDataAll] = useState([]);

  useEffect(() => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: "",
      head: "",
      isEdit: false,
    });
    getEmployeeDetails(employeeData.id);
  }, [refresh]);

  const data = {
    January: {
      projected: { basic: 30000, tds: 1000, pf: 1200, pt: 200, total: 29600 },
      actual: { basic: 31000, tds: 1100, pf: 1250, pt: 220, total: 29850 },
    },
    February: {
      projected: { basic: 30000, tds: 1000, pf: 1200, pt: 200, total: 29600 },
      actual: { basic: 30500, tds: 1050, pf: 1220, pt: 210, total: 29820 },
    },
    March: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 33000, tds: 1250, pf: 1450, pt: 260, total: 31740 },
    },
    April: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 32500, tds: 1250, pf: 1420, pt: 240, total: 31610 },
    },
    May: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 33000, tds: 1250, pf: 1450, pt: 260, total: 31740 },
    },
    June: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 32500, tds: 1250, pf: 1420, pt: 240, total: 31610 },
    },
    July: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 33000, tds: 1250, pf: 1450, pt: 260, total: 31740 },
    },
    August: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 32500, tds: 1250, pf: 1420, pt: 240, total: 31610 },
    },
    September: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 33000, tds: 1250, pf: 1450, pt: 260, total: 31740 },
    },
    October: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 32500, tds: 1250, pf: 1420, pt: 240, total: 31610 },
    },
    November: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 33000, tds: 1250, pf: 1450, pt: 260, total: 31740 },
    },
    December: {
      projected: { basic: 32000, tds: 1200, pf: 1400, pt: 250, total: 31550 },
      actual: { basic: 32500, tds: 1250, pf: 1420, pt: 240, total: 31610 },
    },
  };

  // Extract months from data keys
  // const months = Object.keys(salaryData?.months.map((month) => month?.month));
  const months = salaryData.map((month) => month?.month) || [];
  // console.log('months ********-*-*-*-*', months);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 50 }, (_, i) => currentYear - i);
  const CustomMenuList = styled("ul")(({ theme }) => ({
    maxHeight: "200px",
    overflowY: "auto",
  }));

  months.map((month) => {
    console.log("month-------", salaryData.find(entry => entry.month === month)?.actualSalary);
    // salaryData[month]
  })

  const handleSalryStructureData = async () => {
    try {
      const response = await EmpSalaryDetails(employeeData.id);
      if (response?.status) {
        console.log('-=-=-=-=-=-=-=-=-=-=-=-=-=-', response.data);

        setSalaryData(response.data?.months);
        setSalaryDataAll(response.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleSalryStructureData();
  }, []);

  let actualTotal = 0;
  let projectedTotal = 0;

  const calculateTotals = (field, month) => {
    return salaryData.reduce((sum, entry) => {
      return entry.month === month ? sum + (entry[field] || 0) : sum;
    }, 0);
  };

  return (
    <div style={{ display: "flex", flex: "row", gap: "16px" }}>
      <Stack gap={2} width={"100%"}>

        <Stack>
          <Box sx={{ width: "100%", margin: "10px", display: "flex", justifyContent: "flex-end" }}>
            <TextField
              sx={{ width: "200px" }}
              select
              label="Financial Year"
              fullWidth
              defaultValue={currentYear}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: "200px", // Limits the dropdown height
                      overflowY: "auto", // Ensures scrollability

                    },
                  },
                },
              }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{ borderRight: "1px solid black" }}
                  >
                    Details
                  </TableCell>
                  {months.map((month) => (
                    <TableCell
                      align="center"
                      colSpan={2}
                      key={month}
                      sx={{ borderRight: "1px solid black" }}
                    >
                      {month}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {months.map(() => (
                    <>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #e0e0e0" }}
                      >
                        Proj.
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid black" }}
                      >
                        Actual
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Row for Basic Salary */}
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid black" }}>
                    Basic Salary
                  </TableCell>
                  {months.map((month) => (
                    <>
                      <TableCell
                        align="center"
                        key={`${month}-projected-basic`}
                        sx={{ borderRight: "1px solid #e0e0e0" }}
                      >
                        {salaryData.find(entry => entry.month === month)?.projectedSalary || 0}
                      </TableCell>
                      <TableCell
                        align="center"
                        key={`${month}-actual-basic`}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        {salaryData.find(entry => entry.month === month)?.actualSalary || 0}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
                {/* Row for TDS */}
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid black" }}>
                    TDS
                  </TableCell>
                  {months.map((month) => (
                    <>
                      <TableCell
                        align="center"
                        key={`${month}-projected-tds`}
                        sx={{ borderRight: "1px solid #e0e0e0" }}
                      >
                        {/* {data[month]?.projected?.tds} */}
                        {salaryData.find(entry => entry.month === month)?.proj_TDS || 0}
                      </TableCell>
                      <TableCell
                        align="center"
                        key={`${month}-actual-tds`}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        {/* {data[month]?.actual?.tds} */}
                        {salaryData.find(entry => entry.month === month)?.actu_TDS || 0}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
                {/* Row for PF */}
                {/* <TableRow>
                  <TableCell sx={{ borderRight: "1px solid black" }}>
                    PF
                  </TableCell>
                  {months.map((month) => (
                    <>
                      <TableCell
                        align="center"
                        key={`${month}-projected-pf`}
                        sx={{ borderRight: "1px solid #e0e0e0" }}
                      >
                        {data[month].projected.pf}
                      </TableCell>
                      <TableCell
                        align="center"
                        key={`${month}-actual-pf`}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        {data[month].actual.pf}
                      </TableCell>
                    </>
                  ))}
                </TableRow> */}
                {/* Row for PT */}
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid black" }}>
                    PT
                  </TableCell>
                  {months.map((month) => (
                    <>
                      <TableCell
                        align="center"
                        key={`${month}-projected-pt`}
                        sx={{ borderRight: "1px solid #e0e0e0" }}
                      >
                        {/* {data[month]?.projected?.pt || 0} */}
                        {salaryData.find(entry => entry.month === month)?.proj_PT || 0}
                      </TableCell>
                      <TableCell
                        align="center"
                        key={`${month}-actual-pt`}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        {/* {data[month]?.actual?.pt || 0} */}
                        {salaryData.find(entry => entry.month === month)?.actu_PT || 0}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
                {/* Row for Total */}
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid black" }}>
                    Total
                  </TableCell>
                  {months.map((month) => (
                    <>
                      <TableCell
                        align="center"
                        key={`${month}-projected-total`}
                        sx={{ borderRight: "1px solid #e0e0e0" }}
                      >
                        {calculateTotals('projectedSalary', month)}
                      </TableCell>
                      <TableCell
                        align="center"
                        key={`${month}-actual-total`}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        {calculateTotals('actualSalary', month)}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>


        {/* <Stack>
          <TableContainer sx={{ borderColor: "#999797" }}>
            <Table>
              <TableHead>
                <TableCell style={{ width: "400px " }}>Deductions</TableCell>
                <TableCell>Monthly</TableCell>
                <TableCell>Annual</TableCell>
                <TableCell>Pay Type</TableCell>
              </TableHead>
              <TableBody style={{ backgroundColor: "white" }}>
                {deduction.map((item) => (
                  <TableRow>
                    <TableCell>{item.deduction}</TableCell>
                    <TableCell>{item.monthly}</TableCell>
                    <TableCell>{item.Annual}</TableCell>
                    <TableCell>{item.paytype}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack> */}
        {/* <Stack className="Sideempdetails" gap={1}>
          <Stack>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={2}
            >
              <Box className="headingPart">
                <span className="mainH1">Salary Payment Details</span>
              </Box>
              <Button
                buttonName="Add Salary"
                startIcon={<MdCurrencyRupee />}
                style={{ float: "right" }}
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "Salary",
                    para: "",
                    head: "Add Salary Details",
                    isEdit: false,
                    isView: false,
                    id: "",
                    employeeData: employeeData,
                  });
                }}
              />
            </Box>
            <Stack gap={2}>
              <Divider />
              <Box className="salaryboxgrid">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Salary Payment Mode</span>
                  <span className="mainH2">Online transfer</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Reimbursement Payment Mode</span>
                  <span className="mainH2">Online transfer</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Tax Regime</span>
                  <span className="mainH2">OLD</span>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack> */}
        {/* <Stack className="Sideempdetails" gap={1}>
          <Stack>
            <Box className="headingPart">
              <span className="mainH1">Statutory details</span>
            </Box>
            <Stack gap={2}>
              <Divider />
              <Box className="salaryboxgrid">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">PF Number</span>
                  <span className="mainH2">APHYD11244123000010123</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">UAN</span>
                  <span className="mainH2">102379691253</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Employee PF Contribution limit</span>
                  <span className="mainH2">15000</span>
                </Box>
              </Box>
              <Box className="salaryboxgrid">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="mainH3">Professional Tax</span>
                  <span className="mainH2">250</span>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack> */}
        {/* <Stack>
          <TableContainer sx={{ borderColor: "#999797" }}>
            <Table>
              <TableHead>
                <TableCell style={{ width: "400px" }}>
                  Salary Components
                </TableCell>
                <TableCell>Monthly</TableCell>
                <TableCell>Annual</TableCell>
                <TableCell>Pay Type</TableCell>
              </TableHead>
              <TableBody style={{ backgroundColor: "white" }}>
                {salarycomponents.map((item) => (
                  <TableRow>
                    <TableCell>{item.salarycompo}</TableCell>
                    <TableCell>{item.monthly}</TableCell>
                    <TableCell>{item.Annual}</TableCell>
                    <TableCell>{item.paytype}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack> */}
        {/* <Stack>
          <TableContainer sx={{ borderColor: "#999797" }}>
            <Table>
              <TableHead>
                <TableCell style={{ width: "400px" }}>
                  Statutory Compliances
                </TableCell>
                <TableCell>Monthly</TableCell>
                <TableCell>Annual</TableCell>
                <TableCell>Pay Type</TableCell>
              </TableHead>
              <TableBody style={{ backgroundColor: "white" }}>
                {companycontribution.map((item) => (
                  <TableRow>
                    <TableCell>{item.companycontribution}</TableCell>
                    <TableCell>{item.monthly}</TableCell>
                    <TableCell>{item.Annual}</TableCell>
                    <TableCell>{item.paytype}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack> */}
        {/* 18-11 start */}



        {/* 18-11 end */}
      </Stack>
      {/* <Stack
        className="Sideempdetails"
        gap={1}
        style={{ height: "fit-content",width:"350px" ,
          position: "sticky",
            top: "10px",
            zIndex: 1, 
        }}
      >
        <Stack>
          <Box className="headingPart">
            <span className="mainH1">Salary Details</span>
          </Box>
          <Stack gap={2}>
            <Divider />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap:"2px"
                }}
              >
                <span  style={{fontSize:"13px",color:"#999797"}}>Annual CTC :</span>
                <span className="mainH2"> ₹6,60,000.00 / Year</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap:"2px"

                }}
              >
                <span  style={{fontSize:"13px",color:"#999797"}}>Monthly CTC :</span>
                <span className="mainH2"> ₹55,000.00 / Month</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap:"2px"

                }}
              >
                <span  style={{fontSize:"13px",color:"#999797"}}>Effective Form :</span>
                <span className="mainH2"> Fabruary 2, 2024</span>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack> */}

      <Modal
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={() => {
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
            isEdit: false,
            isView: false,
            id: "",
          });
        }}
        setRefresh={setRefresh}
        refresh={refresh}
        handelClick={() => { }}
      />
    </div>
  );
};

export default SalaryandTaxMang;
