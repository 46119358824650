import { deleteRequest, get, post, put } from "../web.request";


export const AdminDetail = async (id) => {
  return await get(`/adminDetail/${id}`);
}

export const AdminList = async (query) => {
  return await get(`/adminList${query}`)
}

export const AdminCreate = async (data) => {
  return await post(`/createAdmin `, data);
}

export const AdminUpdate = async (id, data) => {
  return await put(`/updateAdmin/${id}`, data);
}

export const AdminDelete = async (id) => {
  return await deleteRequest(`/deleteAdmin/${id}`);
}

export const AdminStatusChange = async (id, data) => {
  return await put(`/statusChangeAdmin/${id}`, data);
}

export const ChnagePasswordOnMail = async (id) => {
  return await put(`/superAdminChangePassword/${id}`);
}

export const changePassword = async (data) => {
  return await put(`/changePassword`, data);
}

export const adminDropdown = async () => {
  return await get(`/adminDropdown`);
}
