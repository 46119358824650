import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters."),
});


// export const changePasswordValidationSchema = Yup.object().shape({
//   newPassword: Yup.string()
//     .required("New Password is required")
//     .matches(
//       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
//       "Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
//     ),
//   confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .matches(
//       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
//       "Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
//     ),
//   oldPassword: Yup.string().required("Password is required."),
// });

export const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
      "Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref('newPassword'), null], 'New Password and Confirm Password must match'),
  oldPassword: Yup.string().required("Old Password is required."),
});


export const addAdminValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  role: Yup.string().required("Role is required"),
  mobile: Yup.string().required("Mobile number is required"),
  image: Yup.mixed().optional(),
  companyId: Yup.string().required("Company is required")
});

export const addAuditorValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  mobile: Yup.string().required("Mobile is required."),
  location: Yup.string().required("Location is required."),
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
});

export const addFormValidationSchema = Yup.object().shape({
  title: Yup.string().required("Form Name is required."),
  expireDate: Yup.string().required("Expires Date is required."),
});

export const roleAddValidationSchema = Yup.object().shape({
  roleName: Yup.string().required("Role Name is required."),
});

export const holidayAddValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required."),
  companyId: Yup.string().required("Company is required."),
  date: Yup.string().required("Date is required."),
  day: Yup.string().required("Day is required."),
  type: Yup.string().required("Type is required."),
  // description: Yup.string().required("Description is required."),
});

export const leaveAddValidationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Employee is required."),
  startDate: Yup.string().required("Start Date is required."),
  endDate: Yup.string().required("EndDate is required."),
  type: Yup.string().required("Type is required."),
  reason: Yup.string(),
});

export const addSalaryDetailValidationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Employee ID is required."),
  basicSalary: Yup.number()
    .typeError("Basic Salary must be a number")
    .required("Basic Salary is required")
    .min(0, "Basic Salary must be at least 0")
    .test("is-decimal", "Basic Salary must be a decimal number", value => Number.isInteger(value) || (value % 1 !== 0 && value >= 0)),
  sequrityDeposit: Yup.string()
    .matches(/^\d+$/, 'Deduction must be a number'),
});


export const leaveSettlementAddValidationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Employee is required."),
  newLeaves: Yup.number().required("New Leaves is required."),
});

export const profileUpdateValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
});

export const addgroupValidationSchema = Yup.object().shape({
  groupName: Yup.string().required("Group Name is required."),
  auditors: Yup.array().required("Auditors is required."),
});


export const formAssignValidationSchema = Yup.object().shape({
  groups: Yup.array().required("Groups is required."),
});

export const extendFormValidationSchema = Yup.object().shape({
  extendDate: Yup.string().required("Date is required."),
});

export const addCompanyValidationSchema = Yup.object({
  companyName: Yup.string()
    .required("Company Name is required")
    .min(2, "Company Name must be at least 2 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  contactNumber: Yup.string()
    .required("Contact Number is required")
    .matches(/^[0-9]+$/, "Contact Number must be numeric"),
  website: Yup.string()
    .url("Invalid URL")
    .required("Website is required"),
  address: Yup.string()
    .required("Address is required"),
  zipcode: Yup.string()
    .required("Zip Code is required")
    .matches(/^[0-9]+$/, "Zip Code must be numeric"),
  state: Yup.string()
    .required("State is required"),
  city: Yup.string()
    .required("City is required"),
});

export const addEmployeeValidationSchema = Yup.object().shape({
  companyId: Yup.number().required('Company ID is required'),
  salutation: Yup.string().oneOf(['Mr', 'Mrs', 'Ms']).required('Salutation is required'),
  firstName: Yup.string().min(2).required('First Name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().min(2).required('Last Name is required'),
  DOB: Yup.date().required('Date of Birth is required'),
  gender: Yup.string().oneOf(['1', '2']).required('Gender is required'),
  contact: Yup.string().length(10).matches(/^[0-9]+$/, 'Contact must be a number').required('Contact is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  designation: Yup.string().required('Designation is required'),
  fathersName: Yup.string().required('Father’s Name is required'),
  fathersContact: Yup.string().length(10).matches(/^[0-9]+$/, 'Father’s Contact must be a number').required('Father’s Contact is required'),
  maritalStatus: Yup.number().oneOf([1, 2]).default(1),
  panNumber: Yup.string()
    .required('PAN Number is required')
    .matches(/^[A-Z]{5}[0-9]{4}[A-Za-z]$/, 'PAN Number must be a valid format')
    .length(10, 'PAN Number must be exactly 10 characters'),


  adharNumber: Yup.string()
    .required('Aadhar Number is required')
    .matches(/^[0-9]{12}$/, 'Aadhar Number must be exactly 12 digits and only contain numbers'),

  passportValidupto: Yup.date(),
  departments: Yup.string().required('Departments are required'),
  branch: Yup.string().required('Branch is required'),
  joining_date: Yup.date().required('Joining Date is required'),
  nameAsPerBank: Yup.string(),
  bankName: Yup.string(),
  bankBranch: Yup.string(),
  accountNumber: Yup.string(),
  IFSCNumber: Yup.string(),
  upiId: Yup.string(),
  employeementType: Yup.number().oneOf([1, 0]).default(1),
  // employeementStatus: Yup.string().required('Employment Status is required'),
  educationLevel: Yup.string().required('Education Level is required'),
  course: Yup.string().required('Course is required'),
  specification: Yup.string().required('Specification is required'),
  startYear: Yup.number().min(1900).max(new Date().getFullYear()).required('Start Year is required'),
  passoutYear: Yup.number().min(1900).max(new Date().getFullYear()).required('Passout Year is required'),
  CGPA: Yup.number()
    .typeError("CGPA must be a number")
    .required("CGPA is required")
    .min(0, "CGPA must be at least 0")
    .max(10, "CGPA must be at most 10")
    .test("is-decimal", "CGPA must be a decimal number", value => Number.isInteger(value) || (value % 1 !== 0 && value >= 0 && value <= 10)),


})

export const employeeDatavalidationSchema = Yup.object().shape({
  salutation: Yup.string().required('Salutation is required'),
  firstName: Yup.string().required('First Name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  gender: Yup.string().required('Gender is required'),
  DOB: Yup.date().required('Date of Birth is required').nullable(),
  contact: Yup.string().required('Contact Number is required').matches(/^\d+$/, 'Contact Number must be a number')
    .min(10, 'Contact Number must be at least 10 digits'),
  // email: Yup.string().email('Invalid email address').required('Email is required'),
  // designation: Yup.string().required('Designation is required'),
  // departments: Yup.string().required('Department is required'),
  companyId: Yup.string().required('Company Name is required'),
  // branch: Yup.string().required('Branch is required'),
  // joining_date: Yup.date().required('Joining Date is required').nullable(),
  // employeementType: Yup.string().required('Employment Type is required'),
  // employeementStatus: Yup.string().required('Employment Status is required'),
  // basicSalary: Yup.number()
  //   .typeError("Basic Salary must be a number")
  //   .required("Basic Salary is required")
  //   .min(0, "Basic Salary must be at least 0")
  //   .test("is-decimal", "Basic Salary must be a decimal number", value => Number.isInteger(value) || (value % 1 !== 0 && value >= 0)),
  // deduction only 0 to 9 allowed
  deduction: Yup.string()
    .matches(/^\d+$/, 'Deduction must be a number'),
  empCode: Yup.string().required('Employee Code is required'),
  altContact: Yup.string().matches(/^\d+$/, 'Alternate Contact Number must be a number'),
  bloodGroup: Yup.string(),
  perosnalEmail: Yup.string().email('Invalid email address'),
  spouseName: Yup.string(),
  spouseContact: Yup.string().matches(/^\d+$/, 'Spouse Contact Number must be a number'),
  maritalStatus: Yup.string().required('Marital Status is required'),
  emergencyContact: Yup.string().required('Emergency Contact Number is required').matches(/^\d+$/, 'Emergency Contact Number must be a number'),
});

export const step1BasicInfo = Yup.object().shape({
  salutation: Yup.string().required('Salutation is required'),
  firstName: Yup.string().required('First Name is required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('Last Name is required'),
  gender: Yup.string().required('Gender is required'),
  DOB: Yup.date().required('Date of Birth is required').nullable(),
  companyId: Yup.string().required('Company Name is required'),

});

export const persnalDatavalidationSchema = Yup.object().shape({
  // maritalStatus: Yup.string().required('Marital status is required'),
  fathersName: Yup.string(),
  fathersContact: Yup.string()
    .matches(/^[0-9]+$/, 'Father’s Contact must be a number')
    .length(10, 'Father’s Contact must be exactly 10 digits'),  // Add other validation rules as needed
  contactOffice: Yup.string().required('Contact Number is required').matches(/^\d+$/, 'Contact Number must be a number')
    .min(10, 'Contact Number must be at least 10 digits'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  designation: Yup.string().required('Designation is required'),
  departments: Yup.string().required('Department is required'),
  branch: Yup.string().required('Branch is required'),
  joining_date: Yup.date().required('Joining Date is required').nullable(),
  employeementType: Yup.string().required('Employment Type is required'),
  // employeementStatus: Yup.string().required('Employment Status is required'),
  motherName: Yup.string(),
  motherContact: Yup.string()
    .matches(/^[0-9]+$/, "Mother's Contact must be a number")
    .length(10, "Mother's Contact must be exactly 10 digits"),


});

export const IdentificationValidationSchema = Yup.object().shape({
  panNumber: Yup.string()
    .required('PAN Number is required')
    .matches(/^[A-Z]{5}[0-9]{4}[A-Za-z]$/, 'PAN Number must be a valid format')
    .length(10, 'PAN Number must be exactly 10 characters'),


  adharNumber: Yup.string()
    .required('Aadhar Number is required')
    .matches(/^[0-9]{12}$/, 'Aadhar Number must be exactly 12 digits and only contain numbers'),

  // passportNumber: Yup.string().required('Passport Number is required'),
  passportValidupto: Yup.date(),
})

export const BankValidationSchema = Yup.object().shape({
  nameAsPerBank: Yup.string()
    .max(50, 'Name should not exceed 50 characters')
    .nullable(),
  bankName: Yup.string()
    .max(50, 'Bank name should not exceed 50 characters')
    .nullable(),
  bankBranch: Yup.string()
    .max(50, 'Branch name should not exceed 50 characters')
    .nullable(),
  accountNumber: Yup.string()
    .nullable(),
  IFSCNumber: Yup.string()
    .nullable(),
  upiId: Yup.string()
    .nullable(),
});

export const EducationValidationSchema = Yup.object().shape({
  educationLevel: Yup.string().required("Education Details is required"),
  course: Yup.string().required("Course is required"),
  specification: Yup.string().required("Specialization is required"),
  startYear: Yup.string().required("Start Year is required"),
  passoutYear: Yup.string().required("Passout Year is required"),
  CGPA: Yup.number()
    .typeError("CGPA must be a number")
    .required("CGPA is required")
    .min(0, "CGPA must be at least 0")
    .max(10, "CGPA must be at most 10")
    .test("is-decimal", "CGPA must be a decimal number", value => Number.isInteger(value) || (value % 1 !== 0 && value >= 0 && value <= 10)),


});

export const addDocumentValidationSchema = Yup.object({
  // employeeId: Yup.string().required('Employee ID is required'),
  adharCard: Yup.mixed().required('File is required'),
  adharCard1: Yup.mixed().required('File is required'),
  panCard: Yup.mixed().required('File is required'),
  panCard1: Yup.mixed().required('File is required'),
  passport: Yup.mixed().required('File is required'),
  educationsDoc: Yup.mixed().required('File is required'),
  preEmpDoc: Yup.mixed().required('File is required'),
  joiningDoc: Yup.mixed().required('File is required'),
});

export const addSalaryValidationSchema = Yup.object().shape({
  companyId: Yup.string().required('Company ID is required'),
});

export const assignemployeeValidationSchema = Yup.object().shape({
  userId: Yup.string().required('User ID is required'),
});

export const addIncrementValidationSchema = Yup.object().shape({
  designation: Yup.string().required('Designation is required'),
  description: Yup.string().required('Description is required').nullable(),
  type: Yup.string().required('Type is required'),
  amount: Yup.number()
    .typeError("Amount must be a number")
    .required("Amount is required")
    .min(0, "Amount must be at least 0")
    .test("is-decimal", "Amount must be a decimal number", value => Number.isInteger(value) || (value % 1 !== 0 && value >= 0 && value <= 10)),
  applicableFrom: Yup.date().required('Applicable Date is required'),
});
