import { IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Close, Done } from '@mui/icons-material'
import { Modal } from '../../../components/Common'
import moment from "moment";
import { getLeaveByEmployee } from '../../../Service/Employee.service'
import {
  RejectLeave,
  ConfirmLeave
} from "../../../Service/Leave.service";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";

const Leaves_Details = ({ employeeId }) => {
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState({
    open: false,
    currentComponent: "",
    head: "",
    para: "",
    id: "",
  })
  const [employeLeavs, setEmployeLeavs] = useState([])
  const dispatch = useDispatch();

  const getLeaveData = async (employeeId) => {
    try {
      const res = await getLeaveByEmployee(employeeId)
      if (res.status) {
        setEmployeLeavs(res.data)
      } else {
        setEmployeLeavs([])
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (employeeId) {
      getLeaveData(employeeId)
    }
  }
    , [refresh])

  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "approved"
        ? await ConfirmLeave(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "reject"
          ? await RejectLeave(isModalOpen?.id)
          : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getLeaveData(employeeId)
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };
  return (
    <>
      <Stack className='Sideempdetails'>

        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Leave Type</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Requested Days</TableCell>
                <TableCell>Leave Balance</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Confirmation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeLeavs &&
                employeLeavs?.map((row) => (
                  <TableRow key={row?.id}>
                    <Tooltip title={`Reason: ${row?.reason}`}>
                      <TableCell>
                        {row?.type === 1 && "1st Half"}
                        {row?.type === 2 && "2nd Half"}
                        {row?.type === 3 && "Casual Leave"}
                        {row?.type === 4 && "Sick leave"}
                        {row?.type === 5 && "Loss of pay / Leave with out pay"}
                      </TableCell>
                    </Tooltip>
                    <TableCell>
                      {moment(row?.startDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(row?.endDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{row?.leaveDays}</TableCell>
                    <TableCell>{row?.leaveBalance || '0'}</TableCell>
                    <TableCell>
                      {row?.status === 1 && "Pending"}
                      {row?.status === 2 && "Confirmed"}
                      {row?.status === 3 && "Rejected"}
                    </TableCell>
                    <TableCell>
                      {row?.status === 1 && (
                        <>
                          <IconButton>
                            <Done
                              sx={{
                                bgcolor: "success.main",
                                color: "white.main",
                                borderRadius: "100%",
                              }}
                              onClick={() =>
                                setIsModalOpen({
                                  open: true,
                                  currentComponent: "approved",
                                  head: "Approve Leave",
                                  para: " Please confirm. Do you want to approve this leave?",
                                  id: row?.id,
                                })
                              }
                            />
                          </IconButton>
                          <IconButton>
                            <Close
                              sx={{
                                bgcolor: "error.main",
                                color: "white.main",
                                borderRadius: "100%",
                              }}
                              onClick={() =>
                                setIsModalOpen({
                                  open: true,
                                  currentComponent: "reject",
                                  head: "Reject Leave",
                                  para: "Are you sure you want to Reject this Leave?",
                                  id: row?.id,
                                })
                              }
                            />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {/* {leavedata == null ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center !important",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="8"
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                ) : leavedata && leavedata.length == 0 ? (
                  <TableRowsLoader colNumber={8} rowsNum={10} />
                ) : (
                  ""
                )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={() => {
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          });
          // formik.resetForm();

        }}
        handelClick={changeStatus}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  )
}

export default Leaves_Details