import React, { useState, useEffect } from "react";
import {
  Button,
  Heading,
  Input,
  MenuButton,
  Modal,
} from "../../../src/components/Common";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import { Pagination, Search } from "../../../src/components/Common/Table";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from "react-icons/md";
import { BsEye, BsPersonCheckFill } from "react-icons/bs";
import { VscFilter } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { EmailOutlined } from "@mui/icons-material";
import {
  DeleteEmployee,
  EmployeeList,
  StatusChangeEmployee,
  uploadBulkData,
  employeeAssign
} from "../../Service/Employee.service";
import {
  filterEmployeeInitialValues,
  uploadEmployeeInitialValues,
  assignemployeeInitialValues
} from "../../helper/initialValues";
import { assignemployeeValidationSchema } from "../../helper/validation";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useFormik } from "formik";
import moment from "moment";
import CommonInput from "../../components/Common/Input";
import CommonSelect from "../../components/Common/Select";
import { CompanyDropdown } from "../../Service/Company.service";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import { useSelector } from "react-redux";

const EmployeeMain = () => {
  const { user } = useSelector((state) => state?.root?.auth);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const [employeeData, setEmployeeData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [files, setFiles] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const dispatch = useDispatch();

  const formik1 = useFormik({
    initialValues: filterEmployeeInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getEmployeeList();
    },
  });

  const formik2 = useFormik({
    initialValues: uploadEmployeeInitialValues,
    onSubmit: async (value) => { },
  });

  const getEmployeeList = async () => {
    setEmployeeData([]);
    let selectQry = `?page=${page + 1
      }&limit=${rowsPerPage}&search=${search}&status=${formik1.values.status
      }&companyId=${formik1.values.company}&fromDate=${formik1.values.fromDate
      }&toDate=${formik1.values.toDate}&sortBy=${sortConfig.key}&sortFlag=${sortConfig.direction
      }`;
    let res = await EmployeeList(selectQry);
    if (res?.status) {
      setEmployeeData(res.data);
      setTotalData(res.total);
    } else {
      setEmployeeData(null);
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, [search, page, rowsPerPage, sortConfig]);

  const getDropdownData = async () => {
    let res = await CompanyDropdown();
    if (res?.status) {
      setCompanyData(res.data);
    } else {
      setCompanyData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  const changeStatus = async () => {
    console.log("dsdsdsdsdsds", files);
    const formData = new FormData();
    formData.append("file", files[0]);
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await StatusChangeEmployee(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
          ? await DeleteEmployee(isModalOpen?.id)
          : await uploadBulkData(formik2.values?.companyId, formData);
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getEmployeeList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
    formik1.resetForm();
    getEmployeeList();
  };
  const [leaveType, setLeaveType] = useState("");

  const handleChange = (event) => {
    setLeaveType(event.target.value);
  };
  const [status, setstatus] = useState("");

  const handleStatus = (event) => {
    setstatus(event.target.value);
  };

  return (
    <>
      <Stack gap={1}>
        <Heading head={"Employees"} />
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button
              buttonName="Upload"
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "uploadProduct",
                  class: "upload_product_modal",
                });
                setFiles([]);
              }}
            />
            <Button
              buttonName={"Add"}
              size="small"
              onClick={() => navigate("/employee-add")}
            />
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              variant="contained"
              // onClick={toggleDrawer}
              onClick={handleFilterClick}
              sx={{ mt: 2 }}
            />
          </Stack>
        </Stack>
        <Collapse Collapse in={showFilter}>
          <Stack
            mb={"12px"}
            sx={{ background: "#151515" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Typography className="inputfont">{"Company"}:</Typography>
              <CommonSelect
                name="company"
                value={formik1.values.company}
                formik={formik1}
                selectList={companyData}
                onChange={(e) =>
                  formik1.setFieldValue("company", e.target.value)
                }
                selectHead="Select Company"
              />
            </Grid>
            {/* <Grid className="filterinput">
              <Typography className="inputfont">{"Employee Type"}:</Typography>
              <CommonSelect
                name="employee"
                value={formik1.values.employee}
                formik={formik1}
                selectList={[{ id: 'Full-time', name: 'Full-time' }, { id: 'Part-time', name: 'Part-time' }, { id: 'Contract', name: 'Contract' }]}
                onChange={(e) => formik1.setFieldValue('employee', e.target.value)}
                selectHead="Select Employee Type"
              />
            </Grid> */}
            <Grid className="filterinput">
              <Typography className="inputfont">{"Status"}:</Typography>
              <CommonSelect
                name="status"
                value={formik1.values.status}
                formik={formik1}
                selectList={[
                  { id: 1, name: "Active" },
                  { id: 0, name: "Inactive" },
                  { id: 2, name: "Delete" },
                ]}
                onChange={(e) =>
                  formik1.setFieldValue("status", e.target.value)
                }
                selectHead="Select Status"
              />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">
                {"From Joining Date"}:
              </Typography>
              <CommonInput
                name="fromDate"
                value={formik1.values.fromDate}
                formik={formik1}
                type="date"
                isDate={true}
              />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">
                {"To Joining Date"}:
              </Typography>
              <CommonInput
                name="toDate"
                value={formik1.values.toDate}
                formik={formik1}
                type="date"
                isDate={true}
              />
            </Grid>

            <Box>
              <Button buttonName={"Filter"} onClick={formik1.handleSubmit} />
            </Box>
            <Box>
              <Button buttonName={"Clear"} onClick={() => clearFilter()} />
            </Box>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Employee</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Work Email</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Phone</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Department</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Company</div>
                  </Stack>
                </TableCell>{" "}
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Date Of Joining</div>
                  </Stack>
                </TableCell>
                {user?.role == 1 && (
                  <TableCell style={{ cursor: "pointer" }}>
                    <Stack
                      flexDirection={"row"}
                      gap={2}
                      alignItems={"center"}
                      justifyContent={"start"}
                    >
                      <div>Assigned</div>
                    </Stack>
                  </TableCell>
                )}
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Status</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"end"}
                  >
                    <div>Action</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeData?.map((data) => (
                <TableRow
                  key={data?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() =>
                    navigate("/employee-details", { state: { id: data?.id, role: user?.role } })
                  }
                >
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data?.salutation}. {data?.firstName} {data?.lastName}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data?.email}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data?.contact}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data?.departments}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {data?.companyname}
                  </TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "left" }}
                  >
                    {moment(data?.joining_date).format("DD/MM/YYYY")}
                  </TableCell>
                  {user?.role == 1 && (
                    <TableCell
                      className="table-hover"
                      style={{ textAlign: "left" }}
                    >
                      {data?.assignedTo || '-'}
                    </TableCell>
                  )}
                  <TableCell>
                    <Switch
                      size="small"
                      checked={data?.status === 1 ? true : false}
                      disabled={formik1.values.status == 2}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({
                          id: data?.id,
                          open: true,
                          currentComponent: "status",
                          head: "Change Status",
                          para: "Are you sure you want to Change the Status?",
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className="chatDropDown">
                        <MenuButton
                          icon={
                            <MdMoreVert
                              style={{ color: "#000", fontSize: "18px" }}
                            />
                          }
                          width="30px"
                          height="33px"
                        />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content
                        className="chatDropDownContent"
                        align="end"
                      >
                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() =>
                              navigate("/employee-details", {
                                state: { id: data?.id, role: user?.role },
                              })
                            }
                          >
                            <BsEye style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"View"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <EmailOutlined style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Send Email"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() =>
                              navigate("/employee-add", {
                                state: { id: data?.id },
                              })
                            }
                          >
                            <AiOutlineEdit style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Edit"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                        {user?.role == 1 && (
                          <DropdownMenu.Item className="chatDropDownMenu">
                            <Stack
                              flexDirection={"row"}
                              gap={1}
                              alignItems={"center"}
                              onClick={() =>
                                setIsModalOpen({
                                  open: true,
                                  currentComponent: "assignemployee",
                                  id: data?.id,
                                  adminId: data?.assignedBy,
                                })
                              }
                            >
                              <BsPersonCheckFill style={{ fontSize: "15px" }} />
                              <Box sx={{ fontSize: "13px" }}>{"Assign"}</Box>
                            </Stack>
                          </DropdownMenu.Item>
                        )}

                        <DropdownMenu.Item className="chatDropDownMenu">
                          <Stack
                            flexDirection={"row"}
                            gap={1}
                            alignItems={"center"}
                            onClick={() =>
                              setIsModalOpen({
                                open: true,
                                currentComponent: "delete",
                                head: "Delete Employee",
                                para: "Are you sure you want to Delete this Employee?",
                                id: data?.id,
                              })
                            }
                          >
                            <HiOutlineTrash style={{ fontSize: "15px" }} />
                            <Box sx={{ fontSize: "13px" }}>{"Delete"}</Box>
                          </Stack>
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {employeeData == null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>
            ) : employeeData && employeeData.length == 0 ? (
              <TableRowsLoader colNumber={8} rowsNum={8} />
            ) : (
              ""
            )}
          </Table>
        </TableContainer>
      </Stack>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {employeeData != null && (
          <Pagination
            count={10}
            shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      <Modal
        modalOpen={isModalOpen}
        setFiles={setFiles}
        files={files}
        handelClick={changeStatus}
        formik={formik2}
        companyData={companyData}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "", adminId: "" });
        }}
        refresh={getEmployeeList}
      />
    </>
  );
};

export default EmployeeMain;
