import { Stack } from '@mui/material'
import React from 'react'
import PDF from './PDF'

const Offerletter = () => {
  return (
    <>
    <Stack gap={2}>
       <PDF />
    </Stack>
    </>
  )
}

export default Offerletter