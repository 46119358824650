import React, { useState, useEffect } from "react";
import { BackButton, Button, Input } from "../../components/Common";
import {
  Box,
  Collapse,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Search, Pagination } from "../../components/Common/Table";
import { VscFilter } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { LeaveList, LeaveTypeDropdown } from "../../Service/Leave.service";
import CommonInput from "../../components/Common/Input";
import { filterHolidayInitialValues } from "../../helper/initialValues";
import { useFormik } from 'formik';
import CommonSelect from "../../components/Common/Select";

const ApproveLeave = () => {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [leavedata, setLeaveData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [LeaveData, setLeaveTypeData] = useState([])

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const getLeaveList = async () => {
    setLeaveData([]);
    let selectQry = `?page=${page + 1}&limit=${rowsPerPage}&search=${search}&status=${2}&sortBy=${sortConfig.key}&sortFlag=${sortConfig.direction}&startDate=${formik1.values.startDate}&endDate=${formik1.values.endDate}&employeeId=${formik1.values.employee}&type=${formik1.values.leaveType}`;
    let res = await LeaveList(selectQry);
    if (res?.status) {
      setLeaveData(res?.data?.leaveData);
      setTotalData(res?.data?.total);
    } else {
      setLeaveData(null);
    }
  }

  useEffect(() => {
    getLeaveList();
  }, [search, page, rowsPerPage, sortConfig]);
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const clearFilter = () => {
    setShowFilter(!showFilter);
    formik1.resetForm();
    getLeaveList();
  };
  const [leaveType, setLeaveType] = useState("");
  const formik1 = useFormik({
    initialValues: filterHolidayInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getLeaveList();
    },
  });
  const handleChange = (event) => {
    setLeaveType(event.target.value);
  };

  const getTypeDropdownData = async () => {
    let res = await LeaveTypeDropdown();
    if (res?.status) {
      setLeaveTypeData(res.data);
    } else {
      setLeaveTypeData([]);
    }
  };

  useEffect(() => {
    getTypeDropdownData();
  }, []);

  return (
    <Stack gap={2}>
      <Box sx={{ display: "flex", gap: "10px", flexDirection: "row" }}>
        <BackButton />
        <Typography fontWeight={600}>Approved Leaves</Typography>
      </Box>
      <Stack

        mt={1}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Search search={search} setSearch={setSearch} setPage={setPage} />
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          {/* <Button buttonName={"Add"} size="small" onClick={() => navigate('/FormContainer')} /> */}
          <Button
            buttonName={<VscFilter />}
            style={{ padding: "10px 11px" }}
            color="white"
            size="small"
            variant="contained"
            // onClick={toggleDrawer}
            onClick={handleFilterClick}
            sx={{ mt: 2 }}
          />
          {/* <ApprovelFilter
              openDrawer={openDrawer}
              toggleDrawer={toggleDrawer}
            /> */}
        </Stack>
      </Stack>
      <Collapse Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          <Grid className="filterinput">
            <Typography className="inputfont">{"Employee"}:</Typography>

            <CommonSelect
              name="employee"
              value={formik1.values.employee}
              formik={formik1}
              selectList={employeeData}
              onChange={(e) => formik1.setFieldValue('employee', e.target.value)}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography className="inputfont">{"Leave Type"}:</Typography>
            <CommonSelect
              label="Type"
              formik={formik1}
              name="leaveType"
              onChange={(e) => formik1.setFieldValue('leaveType', e.target.value)}
              value={formik1.values.leaveType}
              selectList={LeaveData}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography className="inputfont">{"Start Date"}:</Typography>
            <CommonInput
              type="date"
              formik={formik1}
              name="startDate"
              isDate={true}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography className="inputfont">{"End Date"}:</Typography>
            <CommonInput
              type="date"
              formik={formik1}
              name="endDate"
              isDate={true}
            />
          </Grid>

          <Box>
            <Button buttonName={"Filter"} onClick={formik1.handleSubmit} />
          </Box>
          <Box>
            <Button buttonName={"Clear"} onClick={() => clearFilter()} />
          </Box>
        </Stack>
      </Collapse>
      <>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Employee Name</TableCell>
                <TableCell>Leave Type</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Requested Days</TableCell>
                <TableCell>LOP Days</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leavedata && leavedata?.map((row) => (
                <TableRow key={row?.id}>
                  <TableCell>{row?.firstName} {row?.lastName}</TableCell>
                  <TableCell>
                    {row?.type === 1 && "1st Half"}
                    {row?.type === 2 && "2nd Half"}
                    {row?.type === 3 && "Casual Leave"}
                    {row?.type === 4 && "Sick leave"}
                    {row?.type === 5 && "Loss of pay / Leave with out pay"}
                  </TableCell>
                  <TableCell>{moment(row?.startDate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{moment(row?.endDate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{row?.leaveDays}</TableCell>
                  <TableCell>{row?.lopDay}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {leavedata != null && (
          <Pagination count={10} shape="rounded"
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
    </Stack>
  );
};

export default ApproveLeave;
