import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Link,
  Font,
} from "@react-pdf/renderer";
import logoBase64 from "../../../assets/images/pdflogo.png"; 
import { BackButton, Button } from "../../../components/Common";
import { Box, Typography } from "@mui/material";

Font.register({
  family: "Calibri",
  fonts: [
    { src: "/fonts/Calibri-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/Calibri-Bold.ttf", fontWeight: "bold" },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "30px 80px 0px 80px",
  },

  infoContainer: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  headerfont: {
    fontSize: 12,
    maxWidth: "250px",
  },
  separator: {
    width: "1500px",
    height: 2,
    backgroundColor: "#e94d11",
    margin: "0px 10px",
    // position: "absolute",
    // bottom: 60,
    // left:0,
    // right:0,
  },

  content: {
    flex: 1,
    fontFamily: "Calibri",

  },
  footer: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "10px 0px 20px 0px",
  },
  fontbold: {
    fontSize: 12,
    fontWeight: "bold",
    // color: "#000",
    color: "#2f3031",
    lineHeight: 1.5,
    textAlign: "justify",
    fontFamily: "Calibri", // Apply Calibri font here
  },
  regularfont: {
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: 1.5,
    color: "#2f3031",
    textAlign: "justify",
    fontFamily: "Calibri", // Apply Calibri font here
  },
  ball: {
    width: 6,
    height: 6,
    borderRadius: 50,
    backgroundColor: "#2f3031",
    marginTop: 3,
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
    marginLeft: 30,
    gap: 5,

  },
  rightside: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: "20px 0px 0px 0px",
    gap: 3,
  },
  stemp: {
    width: 200,
    height: 50,
    border: "1px",
    borderColor: "#2f3031",
    borderStyle: "dashed",
    marginTop: 3,
  },
  section: {
    marginBottom: 10,
  },
  bulletPoint: {
    width: 10,
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 5,
  },
  bulletItem: {
    flexDirection: "row",
    marginBottom: 5,
    marginLeft: 16,
    gap: 9,
  },
  bulletText: {
    fontSize: 12,
    textAlign: "justify",
    flex: 1,
    color: "#2f3031",
    lineHeight: 1.1,
    fontFamily: "Calibri",
  },
});

// Document Component
const Letterhead = () => (
  <Document>
    <Page style={styles.page} size="A4">
      {/* Header */}
      <View style={styles.header} fixed>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "0px 0px 20px 0px",
          }}
        >
          <Image src={logoBase64} style={{ width: 150 }} />
        </View>
      </View>
      {/* Body */}
      <View style={styles.content}>
        <View
          style={{
            textAlign: "center",
            fontWeight: "extrabold",
            textTransform: "capitalize",
            padding: "20px 0px 30px 0px",
            fontSize: 14,
            textDecoration: "underline",
          }}
        >
          <Text>OFFER LETTER</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "flex-start",
          }}
        >
          <View>
            <Text style={styles.fontbold}>10 August 2024</Text>
          </View>
          <View>
            <Text style={styles.regularfont}>To,</Text>
            <Text style={styles.fontbold}>Mr. Harsh Jani</Text>
            <Text style={styles.fontbold}>A-104, Sadguru Darshan Complex,</Text>
            <Text style={styles.fontbold}>Kuwadva Road, </Text>
            <Text style={styles.fontbold}>Rajkot -360 003 </Text>
          </View>
          <View>
            <Text style={styles.fontbold}>Dear Harsh, </Text>
          </View>
          <View style={{marginBottom:"10px"}}>
            <Text style={styles.regularfont}>Congratulations!!! </Text> 
          </View>
        </View>
        <View>
          <Text style={styles.regularfont}>
            We are pleased to offer you the full-time, position of
            <Text style={styles.fontbold}> Export Executive </Text> at
            <Text style={styles.fontbold}> ASIA PACIFIC Group </Text>
            with a starting of
            <Text style={styles.fontbold}> 15th April 2024. </Text>
            You will be reporting directly to
            <Text style={styles.fontbold}> Mr. Nishit Shah </Text>
            at
            <Text style={styles.fontbold}> Ahmedabad </Text>
            office. The office timing will be 10:00 AM to 7:00 PM and 6 Days a
            week. We believe your skills and experience is an excellent match
            for our company.
          </Text>
        </View>
        <View style={{ marginTop: "10px" }}>
          <Text style={styles.regularfont}>
            The monthly starting salary for this position is
            <Text style={styles.fontbold}> INR 33,000 </Text>
            to be paid on a monthly basis. The company will retain 10% of the
            offered salary every month from your salary for the duration of 12
            months and it will be reverted back via cheque after a completion of
            your 1 year stay with a company.
          </Text>
        </View>
        <View style={{ marginTop: "10px" }}>
          <View>
            <Text style={styles.regularfont}>Dress Code: </Text>
          </View>
          <View >
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
              A modest, elegant and appropriate dress code must be followed.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
              Funky trousers, Funky shirts, short shirts shall not be allowed
              in the ASIA PACIFIC Premises.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
              Shirt should be tucked in compulsorily.
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: "10px" }}>
          <View>
            <Text style={styles.regularfont}>Salary: </Text>
          </View>
          <View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                Your monthly salary will be deposit in your respective bank
                account only.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                Advance of salary during the month is strictly prohibited.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                A Progressive Professional Tax or TDS as per the Government
                guidelines will be deducted from the salary.
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: "10px" }} break>
          <View>
            <Text style={styles.regularfont}>Leave policy: </Text>
          </View>
          <View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                You are entitled to paid leave of only two (2) days throughout
                the month after completion of probation period of 6 months. Any
                additional leave taken will result in deduction of salary.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                Any pre-planned leave required has to be informed to the
                respective head one week before and get it confirmed in writing.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                Prior written notification is necessary to the respective head
                for any leave of absence taken and leave note should be
                submitted for the same. If not two days salary shall be deducted
                for the same.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                For any sudden leave, you require to give a call to the
                respective head in the morning latest by 9:30 a.m. (messages
                will not be permitted).
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: "10px" }}>
          <View>
            <Text style={styles.regularfont}>
              Benefits and Staff Appraisals:{" "}
            </Text>
          </View>
          <View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                Yearly minimum 10% increment would be given on the basic salary.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                Continues appraisal of staff is done to improve the quality of
                work.
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: "10px" }}>
          <View>
            <Text style={styles.regularfont}>Withdrawal of Service:</Text>
          </View>
          <View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                You can withdraw from your services after serving two month
                notice period prior to leaving.
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: "10px" }}>
          <View>
            <Text style={styles.regularfont}>Termination:</Text>
          </View>
          <View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                You will be given a written warning if you do not follow the
                rules and regulation of the Company.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                You shall be terminated after three written warning if the
                management is not satisfied with the work or behavior of the
                respective individual with one month prior notice.
              </Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.ball}></Text>
              <Text style={styles.bulletText}>
                If the reason is very grave, you will be terminated instantly
                without prior notice.
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: "20px" }}>
          <Text style={styles.regularfont}>
            You will have to submit photocopy of original documents of
            <Text style={styles.fontbold}> Education </Text>
            with
            <Text style={styles.fontbold}> Pan Card </Text>
            and
            <Text style={styles.fontbold}> Aadhar Card </Text>
            at the time of Appointment.
          </Text>
        </View>
        <View style={{ marginTop: "10px" }} >
          <Text style={styles.regularfont}>
            The board of management reserves the right to amend, modify, alter
            or vary the terms and conditions of service attached to this
            appointment. Management’s decision would be final regarding any
            matter.
          </Text>
        </View>
        <View style={{ marginTop: "10px" }} >
          <Text style={styles.regularfont}>
            We are excited to have you join our team! If you have any questions,
            please feel free to reach out at any time.
          </Text>
        </View>
        <View style={{ marginTop: "20px" }} break>
          <Text style={styles.regularfont}>
            Please confirm your acceptance of this offer by signing and
            returning this letter by
            <Text style={styles.fontbold}> 09/11/2024</Text>.
          </Text>
        </View>
        <View style={styles.rightside}>
          <View style={styles.stemp} />
          <Text style={styles.regularfont}>Pratik Patel </Text>
          <Text style={styles.regularfont}>Partner </Text>
        </View>
        <View style={{ marginTop: "40px" }}>
          <Text style={styles.regularfont}>
            I hereby declare that I have read the terms and conditions of this
            offer letter and promise to abide by them.
          </Text>
        </View>
        <View style={{ marginTop: "20px" }}>
          <Text style={styles.regularfont}>
            Employee: _________________________
          </Text>
          <Text style={styles.regularfont}>
            Date: _________________________
          </Text>
        </View>
      </View>

      {/* footer */}
      <View style={styles.footer} fixed>
        <View style={styles.separator} />
        <Text
          style={{ fontWeight: 600, fontSize: 11, padding: "10px 0px 5px 0px" }}
        >
          306, Eden Ceramic City, Opp. Hotel Ravi Residency, NH 8A, Lalpar,
          Morbi-363642, Gujarat
        </Text>
        <Text style={{ fontWeight: 600, fontSize: 11 }}>
          <Link
            style={{ color: "black", textDecoration: "none" }}
            href="mailto:info@asiapacificceramic.com"
          >
            info@asiapacificceramic.com
          </Link>{" "}
          | &nbsp;
          <Link
            style={{ color: "black", textDecoration: "none" }}
            href="https://www.asiapacificceramic.com/en"
          >
            www.asiapacificceramic.com
          </Link>
        </Text>
      </View>
    </Page>
  </Document>
);


const PDF = () => (
  <>
  <Box sx={{ padding: "20px", textAlign: "Left",display:"flex",justifyContent:"space-between",flexWrap:"wrap",gap:"10px" }}>
    <Typography>Download Your Letterhead PDF</Typography>
    <PDFDownloadLink document={<Letterhead />} fileName="letterhead.pdf">
      {({  loading, error }) => {
        if (loading) return "Loading document...";
        if (error) {
          console.error("Error generating PDF:", error);
          return "Error generating PDF";
        }
        return <Button buttonName="Download" color="white" />;
      }}
    </PDFDownloadLink>
  </Box>
    <PDFViewer style={{ width: "100%", height: "600px" }}>
      <Letterhead />
    </PDFViewer>
  
  </>
);

export default PDF;
