import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Select,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button } from "../../../components/Common";
import CommonSelect from "../../../components/Common/Select";
import { adminDropdown } from "../../../Service/Admin.service";
import { useEffect } from "react";
import { useFormik } from "formik";
import { assignemployeeInitialValues } from "../../../helper/initialValues";
import { assignemployeeValidationSchema } from "../../../helper/validation";
import { employeeAssign } from "../../../Service/Employee.service";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";

const Assign_Employee_Modal = ({ handleClose, modalOpen, handelClick, refresh }) => {

  const [userData, setUserData] = React.useState([]);
  const dispatch = useDispatch();

  const getDropdownData = async () => {
    let res = await adminDropdown();
    if (res?.status) {
      setUserData(res.data);
    } else {
      setUserData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  const formik3 = useFormik({
    initialValues: assignemployeeInitialValues,
    onSubmit: async (value) => {
      const payload = {
        userId: value.userId,
      };
      let res = await employeeAssign(modalOpen?.id, payload);

      console.log("res", res);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        formik3.resetForm();
        handleClose();
        refresh(modalOpen?.id)
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: assignemployeeValidationSchema,
  });

  useEffect(() => {
    if (modalOpen?.adminId) {
      formik3.setFieldValue("userId", modalOpen?.adminId);
    }
  }, [modalOpen]);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Assign Employee
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: { xs: "100%", sm: "500px" } }}>
        <Box>
          <FormControl fullWidth>
            <CommonSelect
              selectList={userData}
              labelinput="Assign To"
              value={formik3?.values?.userId}
              formik={formik3}
              onChange={(e) =>
                formik3?.setFieldValue("userId", e.target.value)
              }

            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"No"}
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName={"Yes"} size="small" onClick={formik3?.handleSubmit} />
      </DialogActions>
    </>
  );
};

export default Assign_Employee_Modal;
