import { deleteRequest, get, post, put } from "../web.request";

export const EmployeeDetail = async (id) => {
  return await get(`/employeeDetail/${id}`);
}

export const EmployeeList = async (query) => {
  return await get(`/employeeList${query}`)
}

export const EmployeeDropdown = async () => {
  return await get(`/employeeDropdown`)
}

export const CreateEmployee = async (data) => {
  return await post(`/createEmployee `, data);
}

export const UpdateEmployee = async (id, data) => {
  return await put(`/updateEmployee/${id}`, data);
}

export const employeeAssign = async (id, data) => {
  return await put(`/employeeAssign/${id}`, data);
}

export const DeleteEmployee = async (id) => {
  return await deleteRequest(`/deleteEmployee/${id}`);
}

export const StatusChangeEmployee = async (id, data) => {
  return await put(`/statusChangeEmployee/${id}`, data);
}

export const CreateDocument = async (data) => {
  return await post(`/createDocument`, data);
}

//createEmpPart1
export const CreateEmpPart1 = async (data) => {
  return await post(`/createEmpPart1`, data);
}

export const uploadBulkData = async (id, data) => {
  return await post(`/bulk/${id}`, data);
}

export const getLeaveByEmployee = async (id) => {
  return await get(`/employeeLeavse/${id}`);
}